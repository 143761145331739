import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const PageBuilderImage = ({ block }) => {
	const { image, alt, caption /* visibility */ } = block;

	return (
		<div>
			<GatsbyImage
				image={image.asset.gatsbyImageData}
				title={caption}
				alt={alt}
				className="image"
			/>
			<p className="caption">{caption}</p>
		</div>
	);
};

export default PageBuilderImage;

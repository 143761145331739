import React from 'react';
import PageBuilderImage from './PageBuilderImage';
import PageBuilderText from './PageBuilderText';

function PageBuilder(props) {
	const { type, addInfo, _rawAddInfo } = props;

	// Load the right component, based on the _type from Sanity
	const Components = {
		pageBuilderImage: PageBuilderImage,
		pageBuilderText: PageBuilderText,
	};

	// 'raw' content needs to be passed in for the PortableText Component
	return addInfo.map((block, index) => {
		//console.log(block._key);
		if (Components[block._type]) {
			return React.createElement(Components[block._type], {
				key: block._key,
				block: block,
				type,
				raw: _rawAddInfo[index],
			});
		}
		return null;
	});
}

export default PageBuilder;

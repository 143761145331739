import React, { useEffect, useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useInView } from 'react-intersection-observer';
import {
	buildThresholdList,
	map_range,
	decimalAdjust,
} from '../functions/functions';

const MainImage = ({ image, caption, alt, metadata }) => {
	const [isObserverActive, setIsObserverActive] = useState(false);

	let observerMarginBottom = '-10%';
	let observerMarginTop = '-10%';
	let observerVisibleArea =
		1 +
		parseFloat(observerMarginBottom) / 100 +
		parseFloat(observerMarginTop) / 100;

	let options = {
		/* Optional options */
		rootMargin: `${observerMarginTop} 0px ${observerMarginBottom}`,
		threshold: buildThresholdList(),
		skip: isObserverActive,
	};

	const { ref, inView, entry } = useInView(options);
	let intersectionRatioInit = inView ? entry.intersectionRatio : null;

	useEffect(() => {
		let mainImageRatio = metadata.dimensions.aspectRatio;
		let viewportHeight = document.documentElement.clientHeight;
		let viewportWidth = document.documentElement.clientWidth;
		let mainImageFullWidth = viewportWidth;
		let mainImageFullHeight = Math.floor(mainImageFullWidth / mainImageRatio);
		/* console.log('Real image Height: ' + metadata.dimensions.height);
		console.log('Real image Width: ' + metadata.dimensions.width);
		console.log('Virtual image Height: ' + mainImageFullHeight);
		console.log('Virtual image Width: ' + mainImageFullWidth);
		console.log('Viewport Height: ' + document.documentElement.clientHeight);
		console.log('Viewport Width: ' + document.documentElement.clientWidth); */

		let mainImageWidth;
		let mainImageWidthCropped;
		document.documentElement.style.setProperty(
			'--mainImageHeight',
			`${mainImageFullHeight}px`
		);
		if (inView && viewportWidth > 980) {
			/* console.log('is in View');
			console.log('Intersection Ratio real: ' + entry.intersectionRatio); */
			let threshold;
			let intersectionRatio = decimalAdjust('floor', intersectionRatioInit, -2);
			//console.log('Intersection Ratio rounded: ' + intersectionRatio);

			// for portrait mode images of landscape images with more height than observers visible area
			if (mainImageFullHeight > viewportHeight * observerVisibleArea) {
				// threshold defines how much of the image should be seen to resize to full width
				threshold = decimalAdjust(
					'floor',
					(observerVisibleArea * viewportHeight) / mainImageFullHeight,
					-2
				);
				//console.log('Threshold: ' + threshold);
				let multiplier = map_range(intersectionRatio, 0, threshold, 0, 1);
				mainImageWidthCropped = mainImageFullWidth * multiplier;
			} else {
				mainImageWidthCropped = mainImageFullWidth * intersectionRatio;
			}
			mainImageWidth = Math.floor(
				map_range(
					mainImageWidthCropped,
					0,
					viewportWidth,
					980,
					mainImageFullWidth
				)
			);
			//let mainImageVisibility = entry.intersectionRatio * 100;
			document.documentElement.style.setProperty(
				'--mainImageWidth',
				`${mainImageWidth}px`
			);

			/* let scaleFactor = map_range(intersectionRatio, 0, threshold, 1.5, 1);
			document.documentElement.style.setProperty(
				'--scaleFactor',
				`${scaleFactor}`
			); */

			if (intersectionRatio === threshold || intersectionRatio === 1) {
				setIsObserverActive(true);
				//console.log('destroy intersection observer');
			}
			let elementOffsetTop = document.getElementById('mainImage').offsetTop;
			if (window.scrollY > elementOffsetTop) {
				document.documentElement.style.setProperty(
					'--mainImageWidth',
					`${viewportWidth}px`
				);
				setIsObserverActive(true);
				//console.log('destroy intersection observer');
			}
		} else if (inView && viewportWidth < 980) {
			// if viewport less than 980px set image width to viewport width
			document.documentElement.style.setProperty(
				'--mainImageWidth',
				`${viewportWidth}px`
			);
		} else if (!inView && viewportWidth < 980) {
			// if viewport less than 980px set image width to viewport width
			document.documentElement.style.setProperty(
				'--mainImageWidth',
				`${viewportWidth}px`
			);
		} else if (!inView && viewportWidth > 980) {
			document.documentElement.style.setProperty('--mainImageWidth', `980px`);
			document.documentElement.style.setProperty('--scaleFactor', `1`);
		}
		window.addEventListener('resize', () => {
			setIsObserverActive(false);
		});
		return () => {
			window.removeEventListener('resize', () => {
				setIsObserverActive(false);
			});
		};
	}, [
		metadata.dimensions.aspectRatio,
		inView,
		intersectionRatioInit,
		observerVisibleArea,
	]);

	//console.log(entry.intersectionRatio);

	return (
		<div id="mainImage" /* className="mainImage" */ ref={ref}>
			<GatsbyImage
				image={image}
				/* id="mainImage" */
				//className="scaleImage"
				className="mainImage"
				imgClassName="scaleImage"
				objectFit="none"
				//alt={alt}
				alt="alt placeholder"
			/>
		</div>
	);
};

export default MainImage;

import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Localize from '../components/Localize';
import Main from '../components/Main';
import MainImage from '../components/MainImage';
import Addresses from '../components/Addresses';
import Video from '../components/Video';
import PageBuilder from '../components/PageBuilder';

const ArtworkPage = ({ data, pageContext, location }) => {
	const { locale } = pageContext;
	const { sanityArtwork } = data;
	const {
		tempLocation,
		video,
		addInfo = {},
		_rawAddInfo = {},
		artwork,
		title,
		information,
		information: { description },
	} = sanityArtwork;
	const {
		image: {
			asset: { gatsbyImageData, metadata },
		},
		alt,
		caption,
	} = artwork;

	//console.log(tempLocation);
	return (
		<Layout
			data={data}
			pageContext={pageContext}
			location={location}
			fromIndex={false}
		>
			<Main
				title={title}
				description={description}
				information={information}
				fromIndex={false}
			/>
			<MainImage
				image={gatsbyImageData}
				alt={alt}
				caption={caption}
				metadata={metadata}
			/>
			{video && (
				<section id="videoEmbed">
					{/* <h3>Video</h3> */}
					<Video
						url={video.url}
						className="youtubeEmbed"
						width="640"
						height="360"
					/>
				</section>
			)}

			{addInfo.length > 0 && (
				<section id="addInfo">
					{locale === 'en' ? (
						<h3>Exhibition Views</h3>
					) : (
						<h3>Ausstellungsansichten</h3>
					)}
					<PageBuilder addInfo={addInfo} _rawAddInfo={_rawAddInfo} />
				</section>
			)}
			{tempLocation.length > 0 && (
				<Addresses locale={locale} tempLocation={tempLocation} />
			)}
		</Layout>
	);
};

export const query = graphql`
	query ($id: String!) {
		allSanityArtwork {
			edges {
				node {
					_id
					location {
						lat
						lng
					}
					title
					artwork {
						_key
						image {
							asset {
								gatsbyImageData
							}
						}
						alt {
							_key
							_type
							de
							en
						}
						caption {
							_key
							_type
							de
							en
						}
					}
					slug {
						current
					}
				}
			}
		}
		sanityArtwork(id: { eq: $id }) {
			_id
			title
			artwork {
				image {
					asset {
						gatsbyImageData(layout: FULL_WIDTH)
						metadata {
							dimensions {
								aspectRatio
								height
								width
							}
						}
					}
				}
				alt {
					_key
					_type
					de
					en
				}
				caption {
					_key
					_type
					de
					en
				}
			}
			slug {
				current
			}
			series {
				seriesTitle
			}
			information {
				description {
					_type
					de: _rawDe
					en: _rawEn
				}
				medium {
					_type
					de
					en
				}
				miscellaneous {
					_type
					de
					en
				}
				size {
					_type
					de
					en
				}
				year
			}
			video {
				url
			}
			location {
				lat
				lng
			}
			...PageBuilder
			tempLocation {
				_key
				_type
				address {
					institution
					street
					streetNo
					postCode
					city
					miscellaneous {
						_type
						de
						en
					}
				}
				location {
					_key
					_type
					lat
					lng
					alt
				}
			}
		}
	}
`;

export default Localize(ArtworkPage);

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const Addresses = ({ locale, tempLocation }) => {
	return (
		<section id="addresses">
			{locale === 'en' ? (
				<h3>
					work currently on display
					<br />
					at the following locations
				</h3>
			) : (
				<h3>
					Die Arbeit wird zurzeit
					<br />
					an diesen Orten ausgestellt
				</h3>
			)}

			<ul>
				{tempLocation.map((node, index) => (
					<li key={node._key}>
						<FontAwesomeIcon icon={faMapMarkerAlt} className="faMapMarkerAlt" />
						<br />
						<a
							href={`https://www.google.com/maps/search/?api=1&query=
								${node.address.institution ? node.address.institution : ''}${
								node.address.institution ? '%2C+' : ''
							}
								${node.address.street ? node.address.street : ''}${
								node.address.street ? '+' : ''
							}
								${node.address.streetNo ? node.address.streetNo : ''}${
								node.address.streetNo ? '%2C+' : ''
							}
								${node.address.postCode ? node.address.postCode : ''}${
								node.address.postCode ? '+' : ''
							}
								${node.address.city ? node.address.city : ''}`}
						>
							{node.address.institution && node.address.institution}
							{node.address.institution && <br />}
							{node.address.street && node.address.street}{' '}
							{node.address.streetNo && node.address.streetNo}
							{node.address.street && <br />}
							{node.address.postCode && node.address.postCode}{' '}
							{node.address.city && node.address.city}
							{node.address.miscellaneous && <br />}
							{node.address.miscellaneous && node.address.miscellaneous}
						</a>
					</li>
				))}
			</ul>
		</section>
	);
};

export default Addresses;

import React from 'react';
import getYouTubeId from 'get-youtube-id';
import YouTube from 'react-youtube';

const Video = ({ url, width, height, className }) => {
	const id = getYouTubeId(url);

	const opts = {
		height: height,
		width: width,
		playerVars: {
			// https://developers.google.com/youtube/player_parameters
			autoplay: 0,
			controls: 0,
			modestbranding: 1,
			rel: 0,
			showinfo: 0,
		},
	};

	return (
		<div className="youtubeContainerWrapper">
			<YouTube
				videoId={id}
				//className={className}
				className="youtubeEmbed"
				containerClassName="youtubeContainer"
				opts={opts} /* onReady={this._onReady} */
			/>
		</div>
	);
};

export default Video;
